import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import VueWechatTitle from "vue-wechat-title";
import axios from 'axios'
import {Button, Popup, Form, Field, Cascader, Checkbox, Dialog, CellGroup, Search ,NoticeBar, Swipe, SwipeItem ,Image as VanImage, Icon,Picker ,Loading ,Area   } from 'vant';
import 'vant/lib/index.css';


const app = createApp(App)
app.use(router)
app.use(VueWechatTitle)
app.use(Button).use(Popup).use(Form).use(Field).use(Cascader).use(Checkbox).use(Dialog).use(CellGroup).use(Search ).use(NoticeBar ).use(Swipe).use(SwipeItem )
    .use(VanImage).use(Icon).use(Picker ).use(Loading).use(Area)

app.mount('#app')
// 定义全局变量
app.config.globalProperties.$axios = axios;
// 服务端返回的执行成功的状态码
app.config.globalProperties.SUCCESS = '0000';


axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if(response.data.status != "0000"){
    Dialog({message: response.data.message});
  }else{
    return response;
  }
}, function (error) {
  // this.$message.error("服务端开小差了，请稍后重试！！！");
  // 对响应错误做点什么
  return Promise.reject(error);
});


