<template>
  <router-view/>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style>


*{
  margin: 0;
  padding: 0;
}

body{
  width: 100%;
  min-width: 1000px;
  margin: 0 auto !important;
}


</style>
